.comic-modal-wrapper {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1003;
    justify-content: center;
    overflow: auto;
    display: none;
    &.visible {
        display: flex;
    }
}
.bg-color.comic-modal {
    background-image: none !important;
    background-color: $black;
}

.comic-modal-message-wrapper {
    //  background-color: rgba(0, 0, 0, 1);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1005;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
.comic-modal-closeMessage {
    display: flex;
    padding: 30px;
    align-items: center;
    flex-direction: column;
    background-color: $bg-modal;
    span {
        color: $white;
        margin-bottom: 30px;
    }
}
.comic-modal-closeMessage-buttons {
    display: flex;
    > button {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
}
.comic-modal-container {
    margin: calc(5% + 30px);
    height: max-content;
    position: relative;
    &.mobile {
        margin: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
.comic-modal {
    position: relative;
    min-width: 300px;
    width: 720px;
    padding: 40px 48px;
    display: flex;
    flex-direction: column;
    color: $white;
    background-color: $bg-modal;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background-image: url('../../images/comic/modal/bg-modal.png');
    background-repeat: no-repeat;
    background-size: cover;
    &.mobile {
        width: 100%;
        height: 100%;
        padding: 0 32px;
        padding: 0 0px;
        border-radius: unset;
        justify-content: center;
        background-image: url('../../images/responsive/bg-responsive.png');
    }
}
.comic-modal-loading-screen {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1004;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: $white;
    .spinner-border {
        width: 3em;
        height: 3em;
    }
}

.comic-modal-button {
    width: 100%;
    position: relative;
    background: linear-gradient(180deg, rgba(7, 12, 15, 0) 0%, rgba(7, 12, 15, 0.3) 100%);
    background-color: #0e1e2a;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 21px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .comic-button {
        margin: 0;
        padding: 6px 20px;
        &.comic-modal-back {
            padding: 9px 20px !important;
        }
        &:last-child {
            border-color: $secondary;
        }
    }
    &.mobile {
        background: transparent;
        border-radius: unset;
        padding: 0;
        button {
            position: absolute;
            top: 48px;
            right: 37px;
            z-index: 5;
            &.comic-button {
                &:last-child {
                    width: 60px;
                }
            }
        }
    }
}
.comic-modal-exit {
    margin-left: auto !important;
    img {
        margin-right: 0 !important;
        width: 15px;
        height: 23px;
    }
}
.comic-modal-back {
    font-style: 0.875em;
    padding: 11.25px 25px !important;
}

.comic-modal-header {
    margin-bottom: 30px;
}
.comic-modal-description {
    width: 100%;
    margin-bottom: 30px;
    p {
        margin: 0;
    }
}
.comic-modal-content {
    min-height: 100px;
    &.hasFooter {
        margin-bottom: 30px;
    }
}
.comic-modal-footer {
    display: flex;
    justify-content: space-between;
    margin-left: -30px;
    margin-top: -30px;
    margin-bottom: -30px;
    width: calc(100% + 60px);
    padding: 30px;
    button {
        margin-bottom: 0 !important;
        &:first-child {
            margin-right: 10px;
        }
    }
}
.responsive-exit {
    &.outline {
        border-color: $secondary;
    }
}

.bg-image.comic-modal {
    padding-top: 140px;
    border-radius: 24px;
    background-image: linear-gradient(180deg, rgba(11, 21, 27, 0) 0%, #050c10 79.55%), url('../../images/soon.png');
}
