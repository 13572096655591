//
// _helper.scss
//

// Headings
.display {
    font-family: Montserrat, sans-serif;
    // text-transform: uppercase;
    font-style: normal;
    line-height: 100%;
    font-weight: 900;
}
.display1 {
    @extend .display;
    font-size: 4em;
}
.display2 {
    @extend .display;
    font-size: 3.5em;
}
.display3 {
    @extend .display;
    font-size: 3em;
}
.display4 {
    @extend .display;
    font-size: 2.5em;
}
.display5 {
    @extend .display;
    font-size: 2em;
}
.display7 {
    @extend .display;
    font-size: 1.25em;
}

// Paragraph
.p {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    line-height: 1.34em;
    font-weight: 400;
}
.p-36 {
    @extend .p;
    font-size: 2.25em;
}
.p-32 {
    @extend .p;
    font-size: 2em;
}
.p-28 {
    @extend .p;
    font-size: 1.75em;
}
.p-27 {
    @extend .p;
    font-size: 1.6875em;
}
.p-24 {
    @extend .p;
    font-size: 1.5em;
}
.p-20 {
    @extend .p;
    font-size: 1.25em;
}
.p-18 {
    @extend .p;
    font-size: 1.125em;
}
.p-16 {
    @extend .p;
    font-size: 1em;
}
.p-14 {
    @extend .p;
    font-size: 0.875em;
}
.p-12 {
    @extend .p;
    font-size: 0.75em;
}
.p-10 {
    @extend .p;
    font-size: 0.625em;
}

// Font Weight
@for $i from 100 through 900 {
    .font-weight-#{$i} {
        font-weight: #{$i};
    }
}

// Scroll
.scrollBar {
    &::-webkit-scrollbar {
        width: 7px;
        background-color: $black;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 5px;
    }
    scrollbar-width: thin;
    scrollbar-color: $primary;
}

// Progress Bar
@each $color, $value in $dao-voting-colors {
    .dao-voting-bg-#{$color} {
        background: $value;
    }
}
@each $color, $value in $dao-voting-shadows {
    .dao-voting-shadow-#{$color} {
        box-shadow: 0px 0px 12px $value;
    }
}
@each $color, $value in $text-shadows {
    .text-shadow-#{$color} {
        text-shadow: 0px 1px 0px $value;
    }
}
