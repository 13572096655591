.progress-bar-wrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    .progress-bar {
        height: 40px;
        border-radius: 10px;
        transform: skewX(-20deg);
        background-color: $progressBar;
        @media screen and (max-width: 768px) {
            height: 32px;
            border-radius: 6px;
        }
        .progress-bar-current {
            height: 100%;
            border-radius: 10px;
            @media screen and (max-width: 768px) {
                border-radius: 6px;
            }
            .progress-bar-label {
                display: flex;
                align-items: center;
                column-gap: 1em;
                height: 100%;
                font-size: 0.75em;
                font-family: 'SFProDisplay';
                font-style: normal;
                color: $textButton;
                padding: 0 1em 0 2.5em;
                transform: skewX(20deg);
                text-transform: uppercase;
                @media screen and (max-width: 768px) {
                    column-gap: 0.5em;
                    padding: 0 1em;
                }
            }
        }
    }
    .progress-bar-percent {
        font-size: 1.25em;
        font-weight: 600;
        font-family: 'Montserrat';
        color: $progressBarText;
        @media screen and (max-width: 768px) {
            font-size: 1em;
        }
    }
    &:first-child {
        .progress-bar-percent {
            color: #24fff2;
        }
    }
}
