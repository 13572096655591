.legal-documents-wrapper {
    max-width: 1440px;
    width: 100%;
    padding: 178px 32px 50px;
    margin: 0 auto;
    background-color: $layout;
    @media screen and (max-width: 768px) {
        padding: 130px 32px 50px;
    }
}
// Privacy Policy
.privacy-policy-title {
    margin-bottom: 50px !important;
}
.privacy-policy-description {
    margin-bottom: 80px;
}
