.comic-book-container {
    width: 100%;
    height: 900px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(180deg, rgba($color: $layout, $alpha: 0) -61.21%, $layout 100%),
        url('../../../images/landing/background/bg-comic-book.jpg');
    @media screen and (min-width: 1920px) {
        height: 1200px;
    }
    @media screen and (max-width: 768px) {
        height: 100%;
        padding-top: 150px;
        background-image: linear-gradient(180deg, rgba($color: $layout, $alpha: 0) -61.21%, $layout 100%),
            url('../../../images/landing/background/mobile/bg-comic-book.jpg');
    }
    @media screen and (max-width: 390px) {
        padding-top: 120px;
    }
    .comic-book-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        max-width: 986px;
        margin: 0 auto;
        @media screen and (max-width: 1919px) {
            max-width: 727px;
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0 32px;
            h1 {
                font-size: 40px;
            }
        }
        @media screen and (max-width: 390px) {
            h1 {
                width: 300px;
                margin-left: 0;
            }
        }
        .comic-book-description {
            column-gap: 1.5em;
            margin: 3.2em 0 0;
            .description-main {
                width: 100%;
                max-width: 576px;
                margin-bottom: 35px;
                @media screen and (min-width: 1920px) {
                    max-width: 624px;
                }
                @media screen and (max-width: 768px) {
                    margin-bottom: 32px;
                }
            }
            .comic-book-description-line {
                max-width: 117px;
                width: 100%;
                height: 1px;
                margin-top: 1.2em;
                background: $title;
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
        .comic-book-counter {
            margin-bottom: 2.5em;
        }
        .comic-book-button {
            display: flex;
            justify-content: flex-start;
            gap: 40px;
            @media screen and (max-width: 768px) {
                width: 100%;
                justify-content: center;
                flex-direction: column;
                gap: 20px;
                margin-top: 62px;
                margin-bottom: 72px;
            }
            .comic-button-content {
                color: $black;
            }
            .comic-button {
                margin: 0 0 0 5px;
                @media screen and (max-width: 768px) {
                    width: calc(100% - 10px);
                    height: 48px;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .comic-button-content {
                        font-size: 16px;
                        font-weight: 800;
                        line-height: 21px;
                        text-align: center;
                    }
                }
            }
            .comic-book-discord-button {
                .comic-button-content {
                    color: $textButtonOutline;
                }
            }
        }
    }
}
