.navbar-toggler {
    color: transparent !important;
    padding: 0;
}
.mobile-toggle-icon {
    max-width: 76px;
    max-height: 48px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    .mobile-toggle-background {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 48px;
        border-radius: 10px;
        transform: matrix(0.93, 0, -0.43, 1, 0, 0);
        background: rgba(175, 172, 210, 0.16);
        .mobile-toggle-hamburger-menu {
            position: relative;
            width: 26.13px;
            height: 21.57px;
            -webkit-transform: skewX(-3deg) rotate(0deg);
            -moz-transform: skewX(-3deg) rotate(0deg);
            -o-transform: skewX(-3deg) rotate(0deg);
            transform: skewX(-3deg) rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            span {
                display: block;
                position: absolute;
                width: 100%;
                height: 4.31px;
                background-color: #47f9db;
                opacity: 1;
                left: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: 0.25s ease-in-out;
                -moz-transition: 0.25s ease-in-out;
                -o-transition: 0.25s ease-in-out;
                transition: 0.25s ease-in-out;
                &:nth-child(1) {
                    top: 0px;
                }
                &:nth-child(2),
                &:nth-child(3) {
                    top: 9px;
                }
                &:nth-child(4) {
                    top: 18px;
                }
            }
        }
        .open {
            -webkit-transform: skewX(25deg) rotate(0deg);
            -moz-transform: skewX(25deg) rotate(0deg);
            -o-transform: skewX(25deg) rotate(0deg);
            transform: skewX(25deg) rotate(0deg);
            span {
                &:nth-child(1) {
                    top: 9px;
                    width: 0%;
                    left: 50%;
                }
                &:nth-child(2) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                &:nth-child(4) {
                    top: 9px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
}
