*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*:focus {
    outline: none;
}
html {
    scroll-behavior: smooth;
}
body {
    width: 100%;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    background-color: $layout;
    font-style: normal;
    font-weight: normal;
    color: $white;
    font-size: 16px;
    @extend .scrollBar;
    @media screen and (min-width: 1920px) {
        font-size: 18px;
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('../../fonts/SFProDisplay-Black.ttf');
}
@font-face {
    font-family: 'CourierNew';
    src: url('../../fonts/CourierNew.ttf');
}
a {
    color: $white;
    text-decoration: none;
    &:hover {
        color: $white;
    }
}
.cursor-pointer {
    cursor: pointer;
}

.tooltip-inner {
    background-color: $layout;
    max-width: 320px;
    padding: 15px;
    border-radius: 8px;
    > p {
        margin-bottom: 10px;
        background: $title;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

// input type=number
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
