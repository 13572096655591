.slider-wrapper {
    position: relative;
    overflow: hidden;
    @media screen and (min-width: 992px) and (max-height: 900px) {
        margin-top: 50px;
    }
    .slider-items-list {
        position: absolute;
        top: 10%;
        right: 0;
        max-height: 676px;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        row-gap: 15px;
        margin-right: 15px;
        .slider-item-wrapper {
            display: flex;
            align-items: center;
            column-gap: 10px;
            .slider-item-label {
                font-size: 12px;
                font-weight: 700;
                opacity: 0;
                color: #21ffef;
                text-transform: uppercase;
            }
            .slider-item-indicator {
                width: 8px;
                height: 8px;
                cursor: pointer;
                background-color: #7d898e;
                transform: rotate(-45deg);
                &.active {
                    background-color: #21ffef;
                }
            }
        }
    }
    .slider-content {
        position: relative;
        height: 100%;
        .slider-content-item {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 4em;
            padding: 0 5em;
            opacity: 0;
            transition: opacity 500ms ease-in-out;
            @media screen and (max-width: 1200px) {
                column-gap: 1em;
            }
            img {
                max-width: 728px;
                width: 100%;
                @media screen and (max-width: 1200px) {
                    max-width: 500px;
                }
            }
        }
        .slider-title-description {
            max-width: 315px;
            width: 100%;
            h2 {
                @media screen and (max-width: 1200px) {
                    font-size: 40px;
                }
            }
            p {
                margin-top: 16px;
            }
        }
    }
}
.swiper-pagination {
    position: absolute !important;
    height: 110px !important;
    bottom: 0 !important;
    display: flex;
    justify-content: center;
    gap: 20px;
    @media screen and (max-width: 768px) {
        gap: unset;
        justify-content: space-evenly;
        padding: 0 11px;
    }
}

.swiper-slide-active {
    z-index: 999 !important;
}

@media screen and (max-width: 1400px) {
    .swiper-pagination {
        bottom: 40px !important;
    }
}
.swiper-mobile-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.swiper-mobile-bullet {
    width: 12px;
    height: 12px;
    transform: rotate(-45deg);
    background: #8eaab8;
    @media screen and (max-width: 768px) {
        width: 8px;
        height: 8px;
    }
}
.slider-swiper-image {
    padding: 0 17px;
    width: 100%;
}
.swiper-mobile-label {
    font-size: 12px;
    font-weight: 700;
    color: #8eaab8;
    opacity: 0;
}
.swiper-mobile-bullet-active {
    .swiper-mobile-bullet {
        background: #21ffef;
    }
    .swiper-mobile-label {
        opacity: 1;
        color: #21ffef;
    }
}
