// Toastify style
.Toastify__toast-container {
    width: auto;
    padding: 0;
    top: 8em;
}
.Toastify__toast-body {
    margin: 0;
    padding: 0;
}
.Toastify__toast {
    min-height: auto;
    margin-bottom: 0;
    padding: 0;
    cursor: default;
}

// Custom style
.toast-message-container {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    font-weight: 400;
    background: rgba(7, 12, 15, 0.5);
    border-radius: 72px;
    padding: 0 5px 0 20px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 570px;
    width: fit-content;
    text-align: right;
    //  .toast-message {}
    .toast-icon {
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        &.t-error {
            background: linear-gradient(49.87deg, #7a3526 -65.99%, #d15a42 71.7%);
        }
        &.t-success {
            background: linear-gradient(94.87deg, #2cfc98 0%, #5afdc2 100%);
        }
    }
}

// Sell Asset style
.message-success-sell {
    > span {
        &:first-child {
            font-weight: 700;
        }
        &:last-child {
            color: $primary;
            font-weight: 400;
            cursor: pointer;
        }
    }
}
