.counter-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 4.5em;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
    .counter-content {
        column-gap: 2em;
    }
    .counter-label {
        max-width: 5.5em;
        line-height: 1em;
        width: 100%;
        font-size: 0.875em;
        color: $secondary;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
            max-width: 100%;
            margin-bottom: 16px;
        }
    }
    .counter-item-wrapper {
        .counter-item-time {
            font-size: 2em;
            font-family: 'Montserrat';
            font-weight: 800;
            text-align: left;
            color: $secondary;
            line-height: 1;
        }
        .counter-item-label {
            font-family: 'Montserrat';
            font-size: 0.75em;
            font-weight: 800;
            color: $counterLabel;
            line-height: 1;
            text-transform: uppercase;
            @media screen and (max-width: 768px) {
                color: #6e828b;
            }
        }
    }
}
