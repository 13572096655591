.comic-button {
    margin: 0.9375em;
    padding: 0.6em 1.8em;
    font-family: 'SFProDisplay', sans-serif;
    font-size: 1em;
    font-weight: 800;
    line-height: 1.3125em;
    border-radius: 0.625em;
    background: $button;
    box-shadow: $buttonShadow;
    text-shadow: 0px 1px 0px #9dffed;
    border: 2px solid #47f9db;
    transform: skewX(-20deg);
    .comic-button-content {
        display: block;
        transform: skew(20deg);
        & > img,
        & > svg {
            margin-right: 0.5em;
            position: relative;
            top: -1px;
        }
    }
    &.outline {
        background: none;
        box-shadow: none;
        text-shadow: none;
        color: #47f9db;
        &.button-disabled {
            cursor: default;
            color: #68899a;
            text-shadow: none;
            border: 3px solid #68899a;
            pointer-events: none;
        }
    }
    &.outline-white {
        color: #d8e8ef;
        border-color: #d8e8ef;
    }
    &.small {
        padding: 0.625em 0.875em;
        font-size: 0.875em;
    }
    &.comic {
        padding: 0.84375em 1.8em;
    }
    &.disabled-full {
        cursor: default;
        color: #68899a;
        text-shadow: none;
        pointer-events: none;
        opacity: 0.4;
        box-shadow: unset;
        img {
            filter: invert(55%) sepia(8%) saturate(1284%) hue-rotate(156deg) brightness(93%) contrast(83%);
        }
    }
    &.disabled-second {
        cursor: not-allowed;
        color: #08131a;
        opacity: 1;
        background: linear-gradient(105.92deg, #b1b1b1 49.72%, #858585 74.01%);
        border-color: transparent;
    }
}

@media screen and (max-width: 1040px) {
    .comic-button.outline.small {
        width: 150px;
    }
}
