.checkbox-field {
    position: relative;
    padding-left: 35px;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 24px;
    p {
        user-select: none;
        color: $blueLight;
        text-transform: uppercase;
    }
}
.checkbox-field input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: #1b3548;
    border-radius: 4px;

    &::after {
        content: '';
        position: absolute;
        display: none;
    }
}
.checkbox-field .checkmark:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid $midnightBlue;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.checked {
    background: $primary;
    &::after {
        display: block;
    }
}
