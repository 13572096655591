.how-to-mint-container {
    width: 100%;
    height: 1700px;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    background-image: url('../../../images/landing/background/bg-how-to.jpg');
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 0%, black 87%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 0%, black 87%, rgba(0, 0, 0, 0) 100%);
    @media screen and (max-width: 768px) {
        height: 100%;
        background-image: url('../../../images/landing/background/mobile/bg-how-to.svg');
        -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 3.5%, black 99%, rgba(0, 0, 0, 0) 100%);
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 3.5%, black 99%, rgba(0, 0, 0, 0) 100%);
    }
    .how-to-mint-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        height: 100%;
        width: 100%;
        max-width: 934px;
        @media screen and (max-width: 768px) {
            overflow: hidden;
        }
        .how-to-mint-title {
            margin-bottom: 128px !important;
            @media screen and (max-width: 768px) {
                margin: 90px 0 50px 0 !important;
                text-align: left !important;
            }
            @media screen and (max-width: 390px) {
                width: 323px;
            }
        }
        .how-to-mint-text {
            max-width: 823px;
            width: 100%;
            position: relative;
            z-index: 5;
            @media screen and (max-width: 991px) {
                padding: 0 32px;
            }
        }
        .how-to-mint-item-wrapper {
            > * + * {
                margin-top: 56px;
            }
            .how-to-mint-item {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                @media screen and (max-width: 576px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .how-to-mint-item-icon {
                    max-width: 162px;
                    max-height: 162px;
                    width: 100%;
                    height: 100%;
                    @media screen and (max-width: 576px) {
                        position: relative;
                        top: 30px;
                        left: -4px;
                    }
                    img {
                        width: 158px;
                        height: 158px;
                        @media screen and (max-width: 768px) {
                            width: 162px;
                            height: 162px;
                        }
                    }
                }
                .how-to-mint-description {
                    line-height: 32px;
                    margin-left: 32px;
                    max-width: 520px;
                    width: 100%;
                    @media screen and (max-width: 768px) {
                        max-width: 284px;
                        margin-left: 40px;
                    }
                }
            }
        }
        .how-to-mint-ellipse-1 {
            width: 479px;
            height: 479px;
            border-radius: 50%;
            background: linear-gradient(180deg, #0a1a27 0%, rgba(6, 16, 25, 0) 100%);
            position: absolute;
            top: 48px;
            left: -76px;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
        .how-to-mint-ellipse-2 {
            width: 852px;
            height: 852px;
            border-radius: 50%;
            background: linear-gradient(90deg, #061019 0%, rgba(6, 16, 25, 0) 94.39%);
            position: absolute;
            bottom: -20%;
            left: -200px;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
        .how-to-mint-ellipse-3 {
            width: 203px;
            height: 203px;
            background: linear-gradient(270deg, #061019 0%, rgba(6, 16, 25, 0) 100%);
            border-radius: 50%;
            position: absolute;
            right: -115px;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }
}
