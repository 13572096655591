.mechanics-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../images/landing/background/bg-mechanics.jpg');
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 0%, black 83%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 0%, black 83%, rgba(0, 0, 0, 0) 100%);
    @media screen and (max-width: 768px) {
        background-image: url('../../../images/landing/background/mobile/bg-mechanics.png');
        -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 0%, black 85%, rgba(0, 0, 0, 0) 100%);
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 0%, black 85%, rgba(0, 0, 0, 0) 100%);
        height: 1200px;
    }
    .mechanics-title {
        max-width: 934px;
        width: 100%;
        @media screen and (max-width: 768px) {
            max-width: unset;
            text-align: left !important;
        }
    }
    .mechanics-description {
        max-width: 648px;
        width: 100%;
        margin: 32px auto;
        @media screen and (max-width: 768px) {
            text-align: left !important;
        }
    }
    .mechanics-details-description {
        max-width: 824px;
        width: 100%;
        margin: 0 auto;
        @media screen and (max-width: 768px) {
            text-align: left !important;
        }
    }
    .mechanics-content {
        @media screen and (max-width: 991px) {
            padding: 0 32px;
            margin-bottom: 50px;
        }
    }
    @media screen and (max-width: 991px) {
        .swiper {
            height: 100%;
        }
        .swiper-wrapper {
            height: auto;
        }
        .swiper-pagination {
            position: relative !important;
            bottom: 0px !important;
        }
    }
}
