.title {
    margin: 0 auto;
    color: $white;
    &.title-gradient {
        background: $title;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    &.title-margin-0 {
        margin: 0;
    }
}
