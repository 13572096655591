#page-topbar {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 178px;
    padding: 1.8em 4.8em;
    background: linear-gradient(180deg, $layout 0%, rgba($layout, 0) 100%);
    @media screen and (max-width: 991px) {
        height: unset;
        padding-left: 32px;
        padding-right: 32px;
    }
    .basic-navbar-nav {
        padding: 0;
    }
    .logo {
        width: 154px;
        height: 54px;
    }
    .navbar-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nav-sidebar {
            @media screen and (max-width: 992px) {
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                right: -102%;
                display: flex;
                background: #1b1f33;
                flex-direction: column;
                padding: 41px 23px 20px;
                overflow: hidden;
            }
        }
        .navmenu-label {
            color: $navLabel;
            text-transform: uppercase;
            padding-left: 17px;
        }
        .navmenu-wrapper {
            display: flex;
            align-items: center;
            column-gap: 52px;
            @media screen and (max-width: 991px) {
                width: 100%;
                padding-left: 17px;
                align-items: unset;
            }
            .navmenu-item {
                position: relative;
                width: fit-content;
                font-size: 0.6875em;
                font-weight: 800;
                text-transform: uppercase;
                cursor: pointer;
                color: $navItem;
                &:hover {
                    opacity: 0.5;
                }

                @media screen and (max-width: 991px) {
                    font-size: 2.5em;
                    color: unset;
                    background: $title;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                &.active {
                    color: $secondary;
                }
            }

            @media screen and (max-width: 991px) {
                .navmenu-item.active::before {
                    position: absolute;
                    bottom: 0;
                    content: '#';
                    height: 8px;
                    width: calc(100% + 55px);
                    transform: skew(-20deg) translateX(-45px);
                    background: $secondary;
                }
            }
        }
        .navmenu-sidebar-footer {
            p {
                text-align: center;
            }
            .navmenu-sidebar-footer-socials {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 32px;
            }
        }
        .comic-button {
            margin: 0;
            &.small {
                padding: 0.64em 0.875em 0.625em;
            }
            img {
                position: relative;
                top: -1.5px;
                width: 16px;
                height: auto;
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .navbar-brand {
        position: relative;
        top: 3px;
    }
}
