/* The container */
.radio-field {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
}

.checkmark-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #112534;
    border-radius: 50%;
    &::after {
        content: '';
        position: absolute;
        display: none;
    }
}

/* On mouse-over, add a grey background color */
.radio-field:hover input ~ .checkmark-radio {
    opacity: 0.7;
}

/* When the radio button is checked, add a blue background */
.radio-field input:checked ~ .checkmark-radio {
    background-color: #112534;
}

/* Show the indicator (dot/circle) when checked */
.radio-field input:checked ~ .checkmark-radio:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-field .checkmark-radio:after {
    top: 7px;
    left: 7px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #21ffef;
}
