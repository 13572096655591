.mint-container {
    width: 100%;
    height: 900px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../images/landing/background/bg-mint-free.jpg');
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 11%);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 11%);
    @media screen and (max-width: 768px) {
        height: 721px;
        -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 7%, black 91%, rgba(0, 0, 0, 0) 100%);
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 7%, black 91%, rgba(0, 0, 0, 0) 100%);
    }
    .mint-content {
        display: flex;
        height: 100%;
        width: 100%;
        max-width: 1088px;
        margin: 0 auto;
        align-items: center;
        position: relative;
        @media screen and (max-width: 768px) {
            overflow: hidden;
        }
        .title {
            margin: 0;
        }
        .mint-wrapper {
            position: relative;
            z-index: 5;
            @media screen and (max-width: 1200px) {
                padding: 0 32px;
            }
        }
        .mint-ellipse {
            width: 681px;
            height: 681px;
            background: linear-gradient(0deg, rgba(6, 16, 25, 0) 0.02%, #061019 100.02%);
            border-radius: 50%;
            position: absolute;
            right: 0;
            @media screen and (max-width: 768px) {
                right: unset;
                left: 130px;
            }
        }
    }
    .mint-counter {
        justify-content: flex-start;
    }
    .mint-description {
        margin: 32px 0;
        width: 100%;
        max-width: 635px;
    }
    .mint-button {
        display: block;
        justify-content: center;
        a {
            display: inline-block;
            @media screen and (max-width: 687px) {
                display: block;
                width: calc(100% - 5px);
            }
        }
        .comic-button-content {
            color: $black;
            @media screen and (max-width: 768px) {
                text-align: center;
            }
            svg {
                margin-right: 9px;
            }
        }
        .comic-button {
            margin: 30px 0 0 0;
            @media screen and (max-width: 1200px) {
                margin: 48px 0 0 0;
            }
        }
    }
}

// Free mint mobile {
.free-mint-mobile {
    height: 100vh !important;
    padding: 0 32px;
}
