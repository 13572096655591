.dao-container {
    position: relative;
    .dao-content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 364px;
        height: 100%;
        max-width: 1184px;
        margin: 0 auto 15em;
        background-image: linear-gradient(180deg, rgba(6, 15, 23, 0) -1.64%, #060f17 59.47%),
            url('../../../../assets/images/landing/dao/bg-dao.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        > h2 {
            @media screen and (max-width: 768px) {
                text-align: left !important;
                margin: 0 0;
                max-width: 296px;
            }
        }
        @media screen and (max-width: 900px) {
            padding: 150px 32px 82px 32px;
            margin: 0 auto;
            background-position-x: 16px;
            background-position-y: 40px;
        }
        @media screen and (min-width: 1920px) {
            max-width: 1596px;
        }
        @media screen and (max-width: 768px) {
            align-items: flex-start;
        }
        .dao-description {
            margin: 32px 0 64px;
            max-width: 876px;
            @media screen and (min-width: 1920px) {
                max-width: 1001px !important;
            }
            @media screen and (max-width: 768px) {
                margin: 32px 0 30px;
                text-align: left !important;
                max-width: 342px;
            }
        }
    }
    .dao-progress-bars {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
        width: 100%;
        @media screen and (max-width: 768px) {
            row-gap: 14px;
        }
    }
}
