.press-wrapper {
    max-width: 1120px;
    width: 100%;
    padding: 178px 0px 288px;
    margin: 0 auto;
    background-color: $layout;
    @media screen and (max-width: 768px) {
        padding: 130px 32px 50px;
    }

    .title {
        margin: 0;
        width: 385px;
        margin-bottom: 60px !important;
        @media screen and (min-width: 769px) and (max-width: 1117px) {
            margin: auto;
        }
    }
    .press-content {
        flex-wrap: wrap;
        gap: 30px;
        @media screen and (min-width: 769px) and (max-width: 1117px) {
            justify-content: center;
        }
        .press-story-box {
            border: 1px solid #8eaab8;
            border-radius: 12px;
            padding: 18px 12px 16px 12px;
            position: relative;

            > img {
                max-width: 100%;
                margin-bottom: 20px;
            }
        }

        .press-story-box-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;
            span {
                font-size: 14px;
                font-weight: 800;
                color: #d8e8ef;
                text-transform: uppercase;
                &:last-child {
                    font-weight: 400;
                    text-transform: capitalize;
                    margin-left: 10px;
                    text-align: right;
                }
            }
        }

        .press-story-box-title {
            font-weight: 700;
            font-size: 18px;
            color: #fff;
            margin-bottom: 23px;
            text-transform: uppercase;
            @media screen and (max-width: 768px) {
                font-size: 16px;
                br {
                    display: none;
                }
            }
        }

        .press-story-box-link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            position: absolute;
            bottom: 15px;
            right: 12px;

            img {
                margin-right: 5px;
            }

            span {
                color: #21ffef;
                font-size: 14px;
                font-weight: 800;
                line-height: 16px;
                text-transform: uppercase;
            }
        }
    }
}
