.newsletters-container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    margin-top: 132px;
    overflow: hidden;
    @media screen and(max-width: 1919px) {
        max-width: 873px;
        margin-top: 224px;
    }
    @media screen and(max-width: 768px) {
        display: flex;
        justify-content: center;
        margin-top: 141px;
    }
    .newsletters-content {
        width: 1170px;
        height: 1142px;
        background: linear-gradient(180deg, #121b23 0%, rgba(18, 27, 35, 0) 100%);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and(max-width: 1919px) {
            width: 873px;
            height: 873px;
        }
        @media screen and(max-width: 768px) {
            height: 563px;
            max-width: 563px;
            min-width: 563px;
        }
        .newsletters-title {
            margin-top: unset;
            @media screen and(max-width: 768px) {
                width: 317px;
            }
        }
        .newsletters-description {
            margin: 23px 0 53px;
            @media screen and(max-width: 768px) {
                width: 258px;
                margin: 24px auto;
            }
        }
        .newsletters-note {
            color: $paleBlue;
            @media screen and(max-width: 768px) {
                width: 229px;
            }
        }
        .newsletters-input-wrapper {
            overflow: hidden;
            -webkit-transform: skewX(-20deg);
            -moz-transform: skewX(-20deg);
            -o-transform: skewX(-20deg);
            transform: skewX(-20deg);
            padding: 0 70px 0 41px;
            border-radius: 0.6em;
            margin-bottom: 10px;
            background: #182d3d;
            @media screen and(max-width: 768px) {
                padding: 0 73px 0 25px;
            }
            .newsletters-input {
                width: 312px;
                height: 55px;
                font-size: 16px;
                box-shadow: unset;
                outline: none;
                border: 0;
                color: $white;
                background: transparent;
                -webkit-transform: skewX(20deg);
                -moz-transform: skewX(20deg);
                -o-transform: skewX(20deg);
                transform: skewX(20deg);
                text-transform: lowercase;
                @media screen and(max-width: 768px) {
                    width: 250px;
                }
                &::placeholder {
                    font-size: 16px;
                    font-weight: 700;
                    color: #7fa1b2;
                    text-transform: capitalize;
                }
                &::-webkit-input-placeholder {
                    font-size: 16px;
                    font-weight: 700;
                    color: #7fa1b2;
                }
                &:-ms-input-placeholder {
                    font-size: 16px;
                    font-weight: 700;
                    color: #7fa1b2;
                }
            }
        }
        .newsletters-button {
            position: absolute;
            top: 4px;
            right: 4px;
            width: 55px;
            height: 47px;
            line-height: 1;
            padding: 0;
            border-radius: 0.6em;
            margin: 0;
            svg {
                margin-right: 0;
            }
        }
    }
    .newsletters-privacy-policy {
        text-decoration: underline;
        text-decoration-color: $primary;
        color: $primary;
    }
    .newsletters-success-subscribed {
        max-width: 359px;
        width: 100%;
        font-size: 1em;
        color: $yellow;
        text-align: center;
        margin: 0 auto;
    }
    .newsletters-not-valid-email {
        font-size: 0.875em;
        color: $red;
        text-align: center;
        padding-left: 19px;
    }
}
