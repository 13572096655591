.rewards-container {
    position: relative;
    width: 100%;
    height: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 120px;
    background-image: url('../../../../assets/images/landing/rewards/bg-rewards.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $layout;
    @media screen and (max-width: 900px) {
        margin-top: 0;
        margin-bottom: 0;
        padding: 82px 32px;
    }
    @media screen and (max-width: 768px) {
        height: 1265px;
        align-items: flex-start;
        background-image: url('../../../../assets/images/landing/background/mobile/bg-rewards.png'),
            url('../../../../assets/images/landing/background/mobile/bg-rewards_gradient.png');
        background-position-y: 418px, 0px;
        background-size: contain, cover;
        -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 0%, black 94%, rgba(0, 0, 0, 0) 100%);
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 0%, black 94%, rgba(0, 0, 0, 0) 100%);
    }
    .rewards-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 902px;
        width: 100%;
        > h2 {
            padding-bottom: 32px;
            max-width: 842px;
            width: 100%;
            @media screen and (max-width: 768px) {
                text-align: left !important;
                font-size: 44px;
            }
        }
        > p {
            margin-bottom: 0;
        }
        @media screen and (min-width: 1920px) {
            max-width: 1100px;
        }
        @media screen and (max-width: 768px) {
            align-items: flex-start;
            text-align: left !important;
        }
        .dao-description {
            @media screen and (max-width: 768px) {
                position: absolute;
                bottom: 80px;
                max-width: 600px;
                text-align: left !important;
            }
            @media screen and (max-width: 650px) {
                max-width: 440px;
            }
            @media screen and (max-width: 450px) {
                position: absolute;
                bottom: 96px;
                max-width: 315px;
                text-align: left !important;
            }
        }
    }
}
