.roadmap-container {
    width: 100%;
    margin: auto;
    max-width: 1100px;
    .roadmap-wrapper {
        position: relative;
        margin-top: 140px;
        .roadmap-border-wrapper {
            position: absolute;
            width: 100%;
            padding: 0 75px 0 55px;
            top: 175px;
            > div {
                position: relative;
                svg {
                    position: absolute;
                    left: -2px;
                    top: -15px;
                }
                .roadmap-border {
                    border-bottom: 1px solid #2f3b40;
                    margin: 35px 0 33px 0;
                }
            }
        }
        .roadmap-title {
            background: $paragraph;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .roadmap-content {
        justify-content: space-between;
        > div {
            position: relative;
            .roadmap-subtitle {
                text-transform: uppercase;
                color: $paleBlue;
                line-height: 16px;
            }
            svg {
                width: 136px;
                height: 136px;
            }
            .roadmap-text {
                color: $morningBlue;
                line-height: 24px;
                margin-top: 69px;
            }
        }
    }
}

// Roadmap mobile
.roadmap-mobile-container {
    width: 100%;
    padding: 0 32px;
    margin: 0 auto;
    @media screen and (min-width: 576px) and (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    > h2 {
        margin-top: 88px !important;
        @media screen and (max-width: 768px) {
            margin-top: 158px !important;
        }
    }
    .roadmap-item {
        position: relative;
        padding-top: 40px;

        .roadmap-title {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 16px;
        }
        .roadmap-subtitle {
            font-size: 12px;
            line-height: 16px;
            color: $paleBlue;
            width: 95px;
            display: flex;
            justify-content: center;
        }
        .roadmap-image-wrapper {
            margin-left: 20px;
            svg {
                width: 65px;
                height: 65px;
                margin: 5px 0 5px -10px;
            }
        }
        ul {
            padding-left: 1em;
            > li {
                @extend .gradient;
                &::marker {
                    font-size: 12px;
                }
            }
            * + * {
                margin-top: 12px;
            }
        }
        &:not(:last-child) {
            .roadmap-subtitle {
                &::after {
                    content: '';
                    position: absolute;
                    height: calc(100% - 23px);
                    bottom: -66px;
                    border-right: 1px solid #2f3b40;
                }
            }
        }
        .roadmap-icon {
            margin-left: -10px;
            img {
                position: relative;
                left: 33px;
                top: -5px;
            }
        }

        @media screen and (min-width: 595px) {
            .roadmap-item-mobile-1 {
                margin-left: -10px;
            }
            .roadmap-item-mobile-2 {
                margin-left: -10px;
            }
            .roadmap-item-mobile-3 {
                margin-left: -28px;
            }
            .roadmap-item-mobile-4 {
                margin-left: 5px;
            }
            .roadmap-item-mobile-5 {
                margin-left: -28px;
            }
        }
    }
}

.roadmap-checkmark {
    width: 31px !important;
    height: 27px !important;
    position: absolute;
    top: 197px;
    left: 73px;
    z-index: 1;
    @media screen and(max-width: 1919px) {
        left: 60px;
    }
}
