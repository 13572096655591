.team-container {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../images/landing/background/bg-team.jpg');
    margin-bottom: 224px;
    @media screen and (max-width: 991px) {
        margin-bottom: 100px;
    }
    @media screen and (max-width: 768px) {
        background-image: url('../../../images/landing/background/mobile/bg-team.jpg');
        margin-bottom: 0;
    }

    .team-wrapper {
        max-width: 1064px;
        margin: 0 auto 53px auto;
        width: 100%;
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0 32px;
            margin-bottom: 0;
        }
        > h2 {
            margin-top: 224px !important;
            margin-bottom: 136px !important;
            @media screen and (max-width: 991px) {
                margin: 100px 0 !important;
            }
            @media screen and (max-width: 768px) {
                text-align: left !important;
            }
        }
    }
    .team-content {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px) {
            display: block;
        }
        .team-items-first-row {
            column-gap: 50px;
            flex-wrap: wrap;
        }
        .team-items-second-row {
            @media screen and (max-width: 991px) {
                gap: 50px;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center !important;
            }
            > div {
                &:last-child {
                    .team-item-position {
                        width: 170px;
                        @media screen and (min-width: 1920px) {
                            width: 188px;
                        }
                        @media screen and (max-width: 768px) {
                            width: 180px;
                        }
                    }
                }
            }
        }
        .team-item {
            img {
                max-width: 312px;
                height: 335px;
                width: 100%;
                @media screen and (max-width: 768px) {
                    height: 331px;
                }
            }
            .team-item-name {
                margin-top: 15px;
                @media screen and (max-width: 768px) {
                    line-height: 40px;
                    font-weight: 600;
                }
            }
            .team-item-position {
                width: 100%;
                line-height: 16px;
                color: $orange;
                text-transform: uppercase;
                margin-top: 4px;
                margin-left: auto;
                margin-right: auto;
                @media screen and (max-width: 768px) {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 40px;
                    margin-top: 0;
                }
            }
            .team-item-description {
                max-width: 312px;
                width: 100%;
                margin: 22px auto 0 auto;
                text-align: center;
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    margin: 10px auto 0 auto;
                }
            }
            .team-item-socials {
                display: flex;
                justify-content: center;
                margin-top: 25px;
                .team-item-social-image {
                    width: 21px;
                    height: 21px;
                }
            }
        }
        > * + * {
            margin-top: 56px;
            @media screen and (max-width: 768px) {
                margin-top: 48px;
            }
        }
        > div.d-flex {
            > * + * {
                @media screen and (max-width: 768px) {
                    margin-top: 48px;
                }
            }
        }

        > div {
            @media screen and (max-width: 768px) {
                display: block !important;
            }
            &:first-child {
                @media screen and (max-width: 991px) {
                    -webkit-order: 1;
                    order: 1;
                }
            }
            &:nth-child(2) {
                @media screen and (max-width: 991px) {
                    -webkit-order: 3;
                    order: 3;
                }
                @media screen and (max-width: 768px) {
                    > div {
                        &:last-child {
                            .team-item-position {
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
            &:last-child {
                @media screen and (max-width: 991px) {
                    -webkit-order: 2;
                    order: 2;
                }
            }
        }
    }
}
