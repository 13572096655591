.footer {
    width: 100%;
    height: 100%;
    margin-top: 22px;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../assets/images/landing/background/bg-footer.jpg');
    @media screen and(max-width: 1919px) {
        margin-top: 199px;
    }
    @media screen and(max-width: 768px) {
        margin-top: 111px;
    }
    .footer-logo {
        width: 148px;
        height: 55px;
    }
    .footer-join {
        margin: 40px 0;
        @extend .gradient;
        @media screen and(max-width: 768px) {
            font-size: 24px;
        }
    }
    .footer-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .footer-social {
            justify-content: center;
            * + * {
                margin-left: 16px;
            }
        }
        .footer-document {
            justify-content: space-between;
            padding: 111px 160px 80px 160px;
            @media screen and (min-width: 1920px) {
                padding: 111px 400px 80px 400px;
            }
            @media screen and (max-width: 991px) {
                padding: 50px;
            }
            > div {
                &:first-child {
                    * + * {
                        margin-left: 48px;
                        @media screen and (max-width: 991px) {
                            margin-left: 0;
                            margin-top: 32px;
                            display: block;
                        }
                    }
                    > a,
                    > span {
                        > p {
                            @extend .gradient;
                            @media screen and (max-width: 991px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
                &:last-child {
                    color: $paleBlue;
                    @media screen and (max-width: 991px) {
                        margin-top: 56px;
                    }
                }
            }
        }
    }
}
