.responsive-page {
    .responsive-page-content {
        width: 100%;
        max-width: 455px;
        margin: 0 auto;
        @media screen and (max-width: 768px) {
            max-width: 280px;
        }
        > h2 {
            margin: 32px 0 !important;
            @media screen and (max-width: 768px) {
                font-size: 40px !important;
                margin: 24px 0 !important;
            }
        }
        .comic-button {
            position: absolute;
            right: 32px;
            top: 48px;
        }
    }

    > button {
        &.free-mint {
            position: absolute;
            right: 25px;
            top: 25px;
            border-color: $red;
        }
    }
}

.responsive-header {
    width: 100%;
    max-width: 455px;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
        max-width: 280px;
    }
    svg {
        @media screen and (max-width: 768px) {
            width: 96px;
            height: 96px;
        }
    }
}

.no-metamask {
    .description-main {
        margin: 30px 0;
    }
    .coming-soon-button {
        &.comic-button {
            display: inline-block;
            .comic-button-content {
                color: #08131a;
            }
        }
    }
    margin: 0 auto;
    max-width: 100%;
    width: 600px;
    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0 32px;
    }
    #comic-modal-exit {
        position: absolute;
        right: 32px;
        top: 32px;
        border-color: $red;
        width: 60px;
    }
}

.coming-soon-assets {
    .counter-wrapper {
        margin-bottom: 30px;
        .counter-label {
            max-width: 100px;
        }
    }
    .comic-button {
        margin-left: 0;
    }
}
