.story-container {
    margin-bottom: 225px;
    width: 100%;
    @media screen and (max-width: 768px) {
        margin-bottom: 88px;
    }
    .story-title {
        position: relative;
        width: 100%;
        height: 774px;
        background-image: linear-gradient(180deg, rgba(6, 15, 23, 0) -73.77%, #060f17 100%),
            url('../../../images/landing/background/bg-story.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (min-width: 1920px) {
            height: 1080px;
        }
        @media screen and (max-width: 768px) {
            height: 740px;
            background-position: center;
            padding: 0 32px;
        }
        .story-title-text {
            position: absolute;
            max-width: 934px;
            bottom: 44px;
            line-height: 56px;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 1;
            @media screen and (min-width: 1920px) {
                font-size: 80px;
                line-height: 72px;
                max-width: 1296px;
                bottom: 216px;
            }
            @media screen and (max-width: 768px) {
                font-size: 40px;
                line-height: 40px;
                text-align: left !important;
                bottom: 94px;
                padding: 0 32px;
                max-width: 450px;
                width: 100%;
            }
        }
    }
    .story-description-wrapper {
        background-color: $layout;
        position: relative;
        padding-top: 72px;
        @media screen and (max-width: 768px) {
            padding-top: 0;
        }
        .story-description-background {
            background-image: url('../../../images/landing/story/mushroom.png'), url('../../../images/landing/story/ellipse56.png');
            background-repeat: no-repeat;
            background-position-x: center, center;
            background-position-y: -50px, 0px;
            width: 100%;
            height: 1145px;
            margin-bottom: -160px;
            @media screen and (min-width: 1920px) {
                height: 1385px;
                background-size: contain;
                margin-bottom: -87px;
            }
            @media screen and (max-width: 768px) {
                height: 678px;
                background-position-y: 0px, 114px;
                background-size: cover, 90%;
                margin-bottom: unset;
            }
        }
    }
    .story-description {
        margin: 120px auto;
        max-width: 886px;
        @media screen and (max-width: 768px) {
            text-align: left !important;
            line-height: 32px;
            padding: 0 32px;
            margin: 40px auto;
        }
    }
    .story-baby-image {
        width: 100%;
        height: auto;
    }
    .story-descriptions {
        max-width: calc(544px * 2 + 24px);
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        bottom: 85px;
        @media screen and (min-width: 1920px) {
            width: calc(544px * 2 + 267px);
        }
        @media screen and (max-width: 1100px) {
            padding: 0 32px;
            flex-direction: column;
            align-items: center;
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            bottom: unset;
        }
    }
    .story-descriptions-column {
        line-height: 32px;
        max-width: 539px;
    }
    .story-video {
        max-width: 1130px;
        width: 100%;
        height: 654px;
        margin: 0 auto;
        @media screen and (min-width: 1920px) {
            max-width: 1592px;
            height: 921px;
            transform: translateY(-72px);
        }
        @media screen and (max-width: 1200px) {
            padding: 0 32px;
        }
        @media screen and (max-width: 768px) {
            height: 279px;
            width: unset;
        }
    }
}
